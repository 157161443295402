var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"import-config-from-chat"},[_c('radio-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'importType',
        'options': _vm.chatImportTypes,
        'disabled': _vm.disabled
      },
    }}}),(_vm.importType)?_c('media-uploader',{attrs:{"disabled":_vm.isLoading || _vm.disabled},on:{"fileLoaded":_vm.handleFileUpload}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }