



















































































































































































































import { InputSetups } from '@/mixins/input-setups'
import { RawTriggerData } from '@/components/TriggerSetup/logic/types/types'

import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { tooltipTrigger } from "piramis-base-components/src/shared/utils/tooltipTrigger";

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'

@Component({
  methods: {
    tooltipTrigger
  },
  components: {
    List,
    Icon
  },
  data() {
    return {
      ListDirection
    }
  }
})
export default class TriggerCardListWrapper extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @Prop() triggers!: Array<RawTriggerData>

  @Prop({ default: () => [] }) userTriggers!: Array<string>

  @Prop({ type: Function }) hasLimits!: (item: any) => string

  @Prop() triggersHighlightTo!: Array<string>

  @Prop() updatedTriggerId!: string | null | undefined

  @Prop({ type: Boolean, required: false, default: false }) readonly!: boolean

  @Prop({ type: Boolean, default: true, required: false }) hasRemove!: boolean

  @Emit('removeTrigger')
  removeTrigger(id: string): string {
    return id
  }

  @Emit('addToActive')
  addToActive(id: string): string {
    return id
  }

  @Emit('removeFromActive')
  removeFromActive(id: string): string {
    return id
  }

  @Emit('editTrigger')
  editTrigger(id: string): string {
    return id
  }

  @Emit('copyTrigger')
  copyTrigger(id: string): string {
    return id
  }

  @Emit()
  exportTrigger(id: string) {
    return id
  }

  @Emit('watchTrigger')
  watchTrigger(id: string): string {
    return id
  }

  @Emit('moveTriggerDirection')
  moveTriggerDirection(id: string, direction: 'left' | 'right'): { id: string, direction: 'left' | 'right' } {
    return { id, direction }
  }

  @Emit('moveTriggerIndex')
  moveTriggerIndex(id: string, newIndex:number): { id: string, newIndex:number } {
    return { id, newIndex }
  }

  changeIndexModalOpen = false

  newIndex = 1

  selectedTrigger: RawTriggerData | null = null

  onOk():void {
    if (this.selectedTrigger && this.selectedTrigger.id) {
      this.moveTriggerIndex(this.selectedTrigger.id, this.newIndex - 1)

      this.changeIndexModalOpen = false
      this.selectedTrigger = null
    }
  }

  handleEditActionClick(trigger:any):void {
    this.editTrigger(trigger.id)
  }

  isActiveTrigger(id: string): boolean {
    return this.userTriggers.some(tokenCode => new RegExp(tokenCode).test(id))
  }

  getMarkerColor(id: string): string {
    if (this.isActiveTrigger(id)) {
      return 'bg-success'
    } else if (!!this.updatedTriggerId && this.updatedTriggerId === id.toString()) {
      return 'bg-warning'
    } else {
      return ''
    }
  }

  selectTrigger(trigger:RawTriggerData):void {
    if (!this.readonly) {
      this.selectedTrigger = trigger

      this.changeIndexModalOpen = true
    }
  }

  disableMove(id: string, direction: 'left' | 'right'): boolean {
    const triggerIndex = this.triggers.findIndex(trigger => trigger.id === id)

    if (direction === 'left') {
      return triggerIndex === 0
    }

    if (direction === 'right') {
      return triggerIndex === this.triggers.length - 1
    }

    return true
  }

  checkLineClampExists<T extends Element>(el: T | undefined) {
    if (el) {
      return el.scrollHeight > el.clientHeight
    }
  }
}
