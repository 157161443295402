import { render, staticRenderFns } from "./ImportConfigs.vue?vue&type=template&id=18e935b3&scoped=true&"
import script from "./ImportConfigs.vue?vue&type=script&lang=ts&"
export * from "./ImportConfigs.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18e935b3",
  null
  
)

export default component.exports