


































import ImportConfigFromNetwork from "@/components/chat/ChatExtra/ImportConfig/components/ImportConfigFromNetwork.vue";
import ImportConfigFromChat from "@/components/chat/ChatExtra/ImportConfig/components/ImportConfigFromChat.vue";

import PiTabs from "piramis-base-components/src/components/PiTabs.vue";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { Component, Emit, Prop, VModel } from "vue-property-decorator";
import Vue from "vue";
import { ImportTypeEnum } from "@/components/chat/ChatExtra/ImportConfig/types";

const enum ImportTabsEnum {
  'File' = 'File',
  'Network' = 'Network'
}

@Component({
  components: {
    ImportConfigFromChat,
    ImportConfigFromNetwork,
    PiTabs,
  },
})
export default class ImportConfigs extends Vue {
  @VModel() isModalActive!: boolean

  @Prop({ required: false, default: null }) importModes!: Array<ImportTypeEnum>

  @Prop({ required: false, default: null }) tabs!: Array<'file' | 'network'>

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Emit()
  done() {
    return null
  }

  importConfigLoading = false

  currentTab: ImportTabsEnum = ImportTabsEnum.File

  get importTabs(): Array<SelectOptionData> {
    return [
      ...!this.tabs || this.tabs.includes('file') ? [ {
        label: this.$t('import_config_tab_file').toString(),
        value: ImportTabsEnum.File,
        icon: {
          name: 'attach_file'
        }
      } ] : [],
      ...!this.tabs || this.tabs.includes('network') ? [ {
        label: this.$t('import_config_tab_network').toString(),
        value: ImportTabsEnum.Network,
        icon: {
          name: 'icon-database',
          iconPack: 'feather'
        }
      } ] : [],
    ]
  }

  handleDone() {
    this.isModalActive = false

    this.done()
  }

  afterModalClose() {
    this.currentTab = ImportTabsEnum.File
  }
}
