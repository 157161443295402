























import { InputSetups } from "@/mixins/input-setups";
import Api from "../../../../../includes/logic/Api";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import ModulesApi from "@/includes/Api/Modules.api";
import { ChatModulesManager, setModules } from "@/includes/logic/Modules/ModulesManager";
import { ImportTypeEnum } from "@/components/chat/ChatExtra/ImportConfig/types";

import MediaUploader from "piramis-base-components/src/components/MediaUploader/MediaUploader.vue";
import { MediaFileData } from "piramis-base-components/src/components/File/types";
import RadioInput from 'piramis-base-components/src/components/Pi/fields/RadioInput/RadioInput.vue'
import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, Mixins, Emit, PropSync, Prop } from 'vue-property-decorator';

@Component({
  components: {
    MediaUploader,
    RadioInput,
  }
})
export default class ImportConfigFromChat extends Mixins(InputSetups) {
  @PropSync('importConfigLoading', { type: Boolean, required: true, default: false }) isLoading!: boolean

  @Prop({ required: false, default: null }) importModes!: Array<ImportTypeEnum>

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Emit()
  done() {
    return null
  }

  importType: ImportTypeEnum | null = null

  someFile: File | null = null

  get uploadParams() {
    const params: Record<string, any> = {
      chat_id: this.$store.getters.chatId.toString()
    }

    switch (this.importType) {
      case ImportTypeEnum.Config:
        params.dont_import_triggers = true;
        break;
      case ImportTypeEnum.Triggers:
        params.dont_import_config = true
        break
    }

    return params
  }

  get chatImportTypes(): Array<SelectOption> {
    const optionLabel = (type: ImportTypeEnum) => this.$t(`import_config_option_${ type.toLowerCase() }`).toString()

    return [
      ...!this.importModes ? [ {
        label: optionLabel(ImportTypeEnum.All),
        value: ImportTypeEnum.All
      } ] : [],
      ...!this.importModes || this.importModes.includes(ImportTypeEnum.Config) ? [ {
        label: optionLabel(ImportTypeEnum.Config),
        value: ImportTypeEnum.Config
      } ] : [],
      ...!this.importModes || this.importModes.includes(ImportTypeEnum.Triggers) ? [ {
        label: optionLabel(ImportTypeEnum.Triggers),
        value: ImportTypeEnum.Triggers
      } ] : [],
      ...!this.importModes || this.importModes.includes(ImportTypeEnum.Modules) ? [ {
        label: optionLabel(ImportTypeEnum.Modules),
        value: ImportTypeEnum.Modules
      } ] : [],
    ]
  }

  importChatConfig(formData: FormData) {
    this.isLoading = true

    Api.sendRequest('tg', 'importchatconfig', formData)
      .then(this.successImport)
      .then(_ => successNotification())
      .catch(errorNotification)
      .finally(() =>{
        this.isLoading = false
        this.someFile = null
        this.done()
      })
  }

  upload() {
    const fd = new FormData()

    Object.entries(this.uploadParams).forEach(([ k, v ]) => {
      fd.append(k, v)
    })

    if (this.someFile) {
      fd.append('config', this.someFile)
    }

    if (this.importType === ImportTypeEnum.Modules) {
      ModulesApi.importModuleConfig(fd)
        .then(({ data }) => {
          // update chat state
          this.$store.dispatch('updateChatState', data)

          // update modules
          setModules(data.chat.modules_config, ChatModulesManager)

          successNotification()
        })
        .catch(errorNotification)
        .finally(() => {
          this.isLoading = false
          this.someFile = null
          this.done()
        })
    } else {
      this.importChatConfig(fd)
    }
  }

  handleFileUpload(file: MediaFileData) {
    this.someFile = file.blobFile

    this.upload()
  }

  successImport() {
    this.$store.dispatch("requestChatConfig", {
      id: this.$store.getters.chatId
    })
  }

  created() {
    if (this.chatImportTypes.length === 1) {
      this.importType = this.chatImportTypes[0].value
    }
  }
}
